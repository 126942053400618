<template>
  <div id="Replacement">
    <audio controls="controls" hidden src="../../assets/images/q2/MP3/3.mp3" ref="audio"></audio>
    <audio controls="controls" hidden src="../../assets/images/q2/MP3/no.mp3" ref="no"></audio>
    <div class="Replacement_box">
      
      <div class="Q_center">
        
        <div class="Top_box">
          
          <div class="Top_list">
            <div
              class="Top_item"
              v-for="(item, index) in XZlist"
              :key="index"
              @click="SCitem(item, index)"
            >
              <!-- <span
                ><img src="@/assets/images/public/Gold.png" alt="" />{{
                  item.bean
                }}</span
              > -->
              
              <div
                class="Top_pic"
                :style="'background-image: url(' + item.lv_bg_image + ')'"
              >
              <p>{{ item.name }}</p>

                <img :src="item.cover" alt="" />
              </div>
            </div>
          </div>
          <div class="JG" v-show="ZongJia!=0">
            <img src="@/assets/images/public/Gold.png" alt="" />
            <!-- 材料总价 -->
            <span>{{ ZongJia.toFixed(2) }}</span>
          </div>
        </div>
        <div class="bigyuan">
          <div class="yuan">
            <div class="Zhi" :style="sty">
              <div class="dian"></div>
            </div>
            {{ Bili }}<span>%</span>
            <div class="dian"><i></i></div>
          </div>
        </div>
        <div class="Top_box">
            <div class="Top_list" style="white-space: pre-wrap">
              <div class="Top_item_t" @click="SCAditem">
                <div class="T_right">
                  
                  <p>{{ AdItem.name }}</p>
                </div>
                <div class="T_pic">
                  <img :src="AdItem.cover" alt="" />
                </div>
          
              </div>
            </div>
            <div class="JG" v-show="AdItem.bean"><img src="@/assets/images/public/Gold.png" alt="" />{{AdItem.bean}}</div>
          </div>
      </div>



      <van-overlay :show="isShow">
        <div class="wrapper" v-if="isShow">
          <div class="Show_box" >
            <div class="Show_title">合成成功</div>
            <div class="Show_list">
              <div class="Show_item">
                <div class="Show_pic">
                  <img :src="HCitem.cover" alt="" />
                </div>
                <p>{{ HCitem.name }}</p>
                <span
                  ><img src="@/assets/images/public/Gold.png" alt="" />{{
                    HCitem.bean
                  }}</span
                >
              </div>
            </div>
            <div class="Show_btns">
              <van-button type="info" @click="GetIshow"></van-button>
            </div>
          </div>
        </div>
      </van-overlay>

      <van-overlay :show="netShow">
        <div class="wrapper">
          <div class="Show_box" >
            <img class="Img" src="@/assets/images/heyue/ZZ.png" alt="" />
            <div class="Show_btns">
              <van-button type="info" @click="GetIshow"></van-button>
            </div>
          </div>
        </div>
      </van-overlay>

      <!-- 玩法说明 -->
      <van-overlay :show="gameshow">
        <div class="wrapper">
          <div class="wrapper-box">
            <div class="wrapper-box-top"></div>
            <div class="cancel">
              <img
                src="@/assets/images/public/close.png"
                alt=""
                @click="gameshow = false"
              />
            </div>
            <span>玩法说明</span>
            <p>
              1.选择一个目标饰品，用您的背包已有的饰品或购买我们提供的材料饰品进行升级。
            </p>
            <p>2.升级成功则直接获得目标饰品，升级失败则失去材料饰品。</p>
            <p>3.升级成功的概率是依据您提供材料饰品的价值而定的。</p>
          </div>
        </div>
      </van-overlay>
    </div>
    <van-tabs v-model="active" animated style="color:white">
      <van-tab title="我的背包">
        <div class="Replacement_item">
          <!-- <i class="II"></i> -->
          <!-- <div class="Replacement_tit">
            <p>饰品材料</p>
            <div class="JG">
              <img src="@/assets/images/public/Gold.png" alt="" />
              材料总价
              <span>{{ ZongJia.toFixed(2) }}</span>
            </div>
          </div> -->

        

          <div class="b_box">
            <!-- <div class="b_box_nav">
              <p>
                已选饰品：<span>{{ XZlist.length }}</span>
              </p>
              <b>我的饰品</b>
              <div class="b_btn" @click="YiJian" style="cursor: pointer">
                一键添加
              </div>
            </div> -->
            <div class="b_list">
              <div
                class="b_item"
                v-for="(item, index) in StorageList"
                :key="index"
                :class="item.lv | LvGuoLv"
                @click="XZitem(item, index)"
                ref="Item"
              >
                <!-- <span
                  ><img src="@/assets/images/public/Gold.png" alt="" />{{
                    item.bean
                  }}</span
                > -->
                <p >{{ item.name }}</p>

                <div class="b_pic">
                  <img :src="item.cover" alt="" />
                </div>
                <span style="font-size: 12px;color:white;font-weight:700"
                  ><img src="@/assets/images/public/Gold.png" alt="" />{{
                    item.bean
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="PageNum">
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="handleCurrentChange"
              :page-size="per_page"
              :pager-count="3"
              :total="PageNum"
            >
            </el-pagination>
          </div>
        </div>
      </van-tab>
       <van-tab title="目标饰品">
        <div class="Replacement_item">
          <i></i>
          <!-- <div class="Replacement_tit">
            <p>目标饰品</p>
            <div class="c_btn" style="cursor: pointer" @click="gameshow = true">
              玩法说明
            </div>
          </div> -->

      

          <div class="b_box">
            <div class="b_box_nav">
              <b>饰品列表</b>
            </div>
            <div class="b_list">
              <div
                class="b_item"
                v-for="(item, index) in SynthesisList"
                :key="index"
                :class="item.lv | LvGuoLv"
                @click="ADitem(item, index)"
                ref="Add"
              >
                <div
                  style="
                    display: flex;
                    font-size: 12px;
                    justify-content: space-around;
                  "
                >
                  <span style="font-size: 12px">{{ item.dura_alias }}</span>
                  <span style="font-size: 12px"
                    ><img src="@/assets/images/public/Gold.png" alt="" />{{
                      item.bean
                    }}</span
                  >
                </div>

                <div class="b_pic">
                  <img :src="item.cover" alt="" />
                </div>
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div class="PageNum">
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="Changepage"
              :page-size="Synthesis_page"
              :pager-count="3"
              :total="SynthesisNum"
            >
            </el-pagination>
          </div>
        </div>
       </van-tab>
    </van-tabs>
    <div class="bottoms">
      <img src="../../assets/images/q2/permutation/initiate.png" @click="postSynthesis" alt="" style="width:100%">
      <!-- <div class="Q_btn" >确认升级</div> -->
    </div>
  </div>
</template>

<script>
import { Storage, Synthesis_list, Synthesis } from "@/network/api.js";
import { Notify } from 'vant';

export default {
  name: "Replacement",
  data() {
    return {
      StorageList: [],
      PageNum: 0,
      per_page: 0,
      page: 1,

      SynthesisList: [],
      SynthesisNum: 0,
      Synthesis_page: 0,
      S_page: 1,

      XZlist: [],

      AdItem: {},
      show: false,

      ZongJia: 0,
      Bili: 0,

      HCitem: {},
      isShow: false,
      sty: "",
      gameshow: false,
      netShow: false,
      Z_key: false,

      // mp3:require('../../assets/images/q2/MP3/3.mp3')
    };
  },

  created() {
    this.GetStorage(), this.GetSynthesis_list();
  },
	filters: {
			LvGuoLv(val){
				if (val == '1') {
					return 'lv1'
				} else if (val == '2') {
					return 'lv2'
				} else if (val == '3') {
					return 'lv3'
				} else if (val == '4') {
					return 'lv4'
				}else if (val == '5') {
					return 'lv5'
				}else if (val == '6') {
					return 'lv6'
				}
			},
		},
  methods: {
    // playMusic(type) {
    //     this.$refs.audio.currentTime = 0;//从头开始播放
      
    //   },

    //装备列表
    GetStorage() {
      Storage(this.page, "0").then((res) => {
        // console.log(res.data.data.data)
        this.StorageList = res.data.data.data;
        this.PageNum = Number(res.data.data.total);
        this.per_page = Number(res.data.data.per_page);
      });
    },
    //装备列表分页
    handleCurrentChange(val) {
      // console.log(val)
      this.page = val;
      this.GetStorage();
      setTimeout(() => {
        for (let i = 0; i < this.StorageList.length; i++) {
          this.$refs.Item[i].style.border = "1px solid transparent";
        }
      }, 300);
    },
    //合成装备列表
    GetSynthesis_list() {
      Synthesis_list(this.S_page).then((res) => {
        
        this.SynthesisList = res.data.data.data;
        this.SynthesisNum = Number(res.data.data.total);
        this.Synthesis_page = Number(res.data.data.per_page);
        // console.log(this.SynthesisNum,this.Synthesis_page);

      });
    },
    //合成装备列表分页
    Changepage(val) {
      this.S_page = val;
      this.GetSynthesis_list()
    },

    // 选中
    XZitem(item, index) {
      // console.log(item,index)
      if (item.Select) {
        delete item.Select;
        this.$refs.Item[index].style.border = "1px solid transparent";
        let Index = this.XZlist.findIndex((v) => {
          return v.id == item.id;
        });
        // console.log(index)
        this.XZlist.splice(Index, 1);
      } else {
        let key = "Select";
        let value = true;
        item[key] = value;
        this.$refs.Item[index].style.border = "1px solid #E9B10E";
        this.XZlist.push(item);
      }
    },
    //删除选中
    SCitem(item, index) {
      // console.log(item)
      // this.XZlist.splice(index,1)
      let Index = this.StorageList.findIndex((v) => {
        return v.id == item.id;
      });
      // console.log(index)
      this.$refs.Item[Index].style.border = "1px solid transparent";
      // console.log(this.$refs.Item[Index])
    },

    // 选中
    ADitem(item, index) {
      if (item.Select) {
        delete item.Select;
        this.$refs.Add[index].style.border = "1px solid transparent";
        this.AdItem = {};
      } else {
        for (let i = 0; i < this.SynthesisList.length; i++) {
          this.$refs.Add[i].style.border = "1px solid transparent";
          delete this.SynthesisList[i].Select;
        }
        let key = "Select";
        let value = true;
        item[key] = value;
        this.$refs.Add[index].style.border = "1px solid #E9B10E";
        this.AdItem = item;
        // console.log(item)
      }
    },

    //删除
    SCAditem() {
      let Index = this.SynthesisList.findIndex((v) => {
        return v.id == this.AdItem.id;
      });
      this.$refs.Add[Index].style.border = "1px solid transparent";
      this.AdItem = {};
    },

    //合成
    postSynthesis() {
      if(this.Bili>100){
         // console.log(this.XZlist)
        let AdArr = this.XZlist.filter((v) => {
          return v.dura_alias == "无";
        });
        // console.log(AdArr.length)  AdArr.length == 0
        if (true) {
          let data = [];

          for (let i = 0; i < this.XZlist.length; i++) {
            data.push({
              id: this.XZlist[i].id,
            });
          }
          this.$refs.audio.play(); //播放
          setTimeout(()=>{
            this.$refs.audio.load();//停止
            this.$refs.no.play();

          },3000);
          // this.mp3.audio.play()
          // console.log(data)

          Synthesis(data, this.AdItem.id).then((res) => {
            this.sty = "animation: zhuan 1s linear; animation-iteration-count:3;";
            // console.log(res)
            if (res.data.data) {
              this.HCitem = res.data.data;
              setTimeout(() => {
                this.isShow = true;
                this.XZlist = [];
                this.GetStorage();
                this.AdItem = {};
                for (let i = 0; i < this.StorageList.length; i++) {
                  this.$refs.Item[i].style.border = "1px solid transparent";
                }
                for (let i = 0; i < this.SynthesisList.length; i++) {
                  this.$refs.Add[i].style.border = "1px solid transparent";
                  delete this.SynthesisList[i].Select;
                }
                this.sty = "";
                this.Bili = 0;
              }, 3000);
            } else {
              setTimeout(() => {
                this.netShow = true;
                this.XZlist = [];
                this.GetStorage();
                this.AdItem = {};
                for (let i = 0; i < this.StorageList.length; i++) {
                  this.$refs.Item[i].style.border = "1px solid transparent";
                }
                for (let i = 0; i < this.SynthesisList.length; i++) {
                  this.$refs.Add[i].style.border = "1px solid transparent";
                  delete this.SynthesisList[i].Select;
                }
                this.sty = "";
              }, 3000);
            }
          });
        } else {
          this.$notify({
            type: "danger",
            message: "已选饰品磨损度不能为无",
          });
        }
      }else{
        Notify({ type: 'warning', message: '不得低于100%' });
      }
     
    },

    GetIshow() {
      this.isShow = false;
      this.netShow = false;
    },

    YiJian() {
      if (this.Z_key == false) {
        let key = "Select";
        let value = true;
        for (let i = 0; i < this.StorageList.length; i++) {
          this.StorageList[i][key] = value;
          this.$refs.Item[i].style.border = "1px solid #E9B10E";
          this.XZlist.push(this.StorageList[i]);
        }
        this.Z_key = true;
      } else {
        for (let i = 0; i < this.StorageList.length; i++) {
          delete this.StorageList[i].Select;
          this.$refs.Item[i].style.border = "1px solid transparent";
          this.XZlist = [];
        }
        this.Z_key = false;
      }
    },
  },
  watch: {
    AdItem(v) {
      if (v.bean == undefined) {
        this.show = false;
      } else {
        this.show = true;
        if (this.ZongJia != 0) {
          let add = 0;
          add = (this.ZongJia / Number(v.bean)) * 100;
          this.Bili = add.toFixed(0);
        }
      }
    },
    XZlist(v) {
      this.ZongJia = 0;
      // console.log(v)
      for (let i = 0; i < v.length; i++) {
        this.ZongJia += Number(v[i].bean);
      }
    },
    ZongJia(v) {
      if (this.AdItem.bean != undefined) {
        let add = 0;
        add = (v / Number(this.AdItem.bean)) * 100;
        this.Bili = add.toFixed(0);
      }
    },
  },
};
</script>

<style lang="scss">
 .JG {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #ccc;
    justify-content: center;

    img {
      width: 10px !important;
      height: 10px;
      margin: 0 !important;
      // margin-right: 5px;
    }

    span {
      font-size: 12px;
      color: #f5953c;
      margin-top: 3px;
      margin-left: 5px;
    }
  }
@keyframes anim { 
	0%   {opacity:0}
  // 50%  {opacity:0.5}
	100% {opacity:1}
}
.van-overlay{
  animation: anim 1s !important;
}
.van-tab {
  background: url('../../assets/images/q2/permutation/btn.png') no-repeat center;
  background-size: 100% 100%;
  color: white;
 width: 50px;
}
.van-tab--active{
  background: url('../../assets/images/q2/permutation/active.png') no-repeat center;
  background-size: 100% 100%;
  color: #34F3FB;
}
.van-tabs__nav {
  background: none;
 
  
}
#Replacement{
  background: url('../../assets/images/q2/permutation/bac.png') no-repeat center;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: 0px 0px;
  // padding-top: 100px;
}
    .Top_box {
      width: 100px;
      height: 120px;
      // background: url(../../assets/images/heyue/back.png) no-repeat center;
      // background: url('../../assets/images/q2/home/swiperbac.png') no-repeat center;

      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-top: 60px;
      flex-wrap: wrap;
	  .Top_list::-webkit-scrollbar {
        width: 4px;
      }
      .Top_list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(255, 255, 255, 0.2);
      }
      .Top_list::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(255, 255, 255, 0.1);
      }
      .Top_list {
        width: 200px;
        height: 110px;
        overflow-x: auto;
        white-space: nowrap;
		    overflow-y: hidden;
        .Top_item {
          width: 100px;
          display: inline-block;
          margin-top: 32px;
          span {
            display: flex;
            width: 100%;
            justify-content: center;
            font-size: 12px;
            color: #fff;

            img {
              width: 12px;
              margin-right: 5px;
            }
          }

          .Top_pic {
            width: 100px;
            height: 65px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            // border: 1px solid #ccc;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          p {
            font-size: 12px;
            padding: 0 10px;
            box-sizing: border-box;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #fff;
          }
        }

        .Top_item_t {
          width: 100%;
          // height: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 10px;
          box-sizing: border-box;
          flex-wrap: wrap;
          margin-top: 30px;
          .T_pic {
            width: 100px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          .T_right {
            width: 80px;
            span {
              font-size: 14px;
              color: #f7953b;
              text-align: right;
              text-overflow: ellipsis;
              white-space: nowrap;
              img {
                width: 15px;
                margin-right: 5px;
                margin-bottom: -2px;
              }
            }
            p {
              width: 80px;
              font-size: 14px;
              word-wrap: break-word;
              color: #fff;
              overflow: hidden;
              /* 第二步：让文本不会换行， 在同一行继续 */
              white-space: nowrap;
              /* 第三步：用省略号来代表未显示完的文本 */
              text-overflow: ellipsis;

            }
          }
        }
      }
    }
  
.bottoms{
  width: 390px;
  height: 100px;
  position: fixed;
  bottom: 0;
  background: url('../../assets/images/q2/permutation/bottom.png') no-repeat center;
  background-size: 100% 100%;
  padding-top: 10px;
  .Q_btn {
      width: 70px;
      height: 30px;
      background-size: 100% 100%;
      background-image: url("../../assets/images/btn.jpg");
      /*background-image: linear-gradient(180deg,#ffd939,#fda539);*/
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      color: #000;
      cursor: pointer;
      &:hover {
        box-shadow: 0 0 8px #ffd939;
      }
    }

}
  .Replacement_item {
    width: 100%;
    // border: 2px solid rgb(117, 120, 169);
    padding-bottom: 10px ;
    background: url('../../assets/images/q2/permutation/bottom.png') no-repeat center;
    background-size: 100% 100%;
    .II {
      display: block;
      width: 100%;
      height: 5px;
      background: url(../../assets/images/heyue/Zui.png) no-repeat center left;
      background-size: contain;
    }

    .Replacement_tit {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      line-height: 40px;
      // border-bottom: 2px solid #e9b10e;

      p {
        font-size: 14px;
        color: #fff;
        font-weight: 900;
        width: 80px;
        text-align: center;
         background: rgb(117, 120, 169);
        //  width: 100%;
         height: 100%;
         margin-left: 0px;
      }
      .c_btn {
        width: 78px;
        height: 32px;
        // border: 2px solid #ffae00;
        font-size: 16px;
        text-align: center;
        line-height: 32px;
        margin-right: 12px;
        color: #ffae00;
      }
     
    }
    .PageNum {
      // border-top: 2px solid #ffae00;
      // padding-top: 10px;
      width: 100%;
      text-align: center;
      margin-top: 20px;
      display: flex;
      justify-content: center;

      li {
        background: #111111 !important;

        &.active {
          background: #4854c9 !important;
        }

        &:hover {
          color: #fff !important;
        }
      }

      .btn-prev,
      .btn-next {
        background: #111111;
      }
    }


    .b_box {
      width: 100%;
      height: 300px;
      background: url(../../assets/images/heyue/di.png) no-repeat center;
      background-size: contain;
      overflow: hidden;

      .b_box_nav {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-size: 16px;
          color: #ccc;

          span {
            color: #f5953c;
          }
        }

        b {
          font-size: 16px;
          color: #ccc;
          margin: 0 20px;
        }

        .b_btn {
          width: 58px;
          height: 24px;
          font-size: 12px;
          background: #ffae00;
          color: #000;
          text-align: center;
          line-height: 24px;
        }
      }
      .b_list::-webkit-scrollbar {
        width: 4px;
      }
      .b_list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(255, 255, 255, 0.2);
      }
      .b_list::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(255, 255, 255, 0.1);
      }
      .b_list {
        padding-top: 10px;
        width: 100%;
        height: 250px;
        margin: 2px auto 0;
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        align-items: flex-start;
        .b_item {
          width: 31%;
          border: 1px solid transparent;
          overflow: hidden;
          margin-left: 2%;
          // margin-right: 2px;
          margin-bottom: 5px;
          background-color: rgba($color: #000000, $alpha: 0.1);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 80%;
          box-sizing: border-box;
          padding-top: 10px;
          padding-bottom: 10px !important;
          
          // &:nth-child(4n) {
          // 	margin-right: 0;
          // }
          &.lv1{
						background-size: 100% 100%;
						border-radius: 5px;
    					// border: 1px solid rgba(233, 212, 90, 0.358);
						background: linear-gradient(to top, rgba(255,0,0,0), rgb(139, 111, 18));
            background: url('../../assets/images/985/home/1.png') no-repeat center;
            background-size: 100% 100%;
					}
					&.lv2{
						background-size: 100% 100%;
						border-radius: 5px;
    					// border: 1px solid rgb(210, 73, 73);
						background: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(133, 46, 46));
            background: url('../../assets/images/985/home/2.png') no-repeat center;
            background-size: 100% 100%;
					}
					&.lv3{
						background-size: 100% 100%;
						border-radius: 5px;
    					// border: 1px solid rgb(203, 70, 203);
						background: linear-gradient(to top, rgba(255,0,0,0), rgb(57,44,83));
            background: url('../../assets/images/985/home/3.png') no-repeat center;
            background-size: 100% 100%;
					}
					&.lv4{
						background-size: 100% 100%;
						border-radius: 5px;
    					// border: 1px solid rgb(86, 86, 214);
						background: linear-gradient(to top, rgba(255,0,0,0), rgb(57,79,102));
            background: url('../../assets/images/985/home/4.png') no-repeat center;
            background-size: 100% 100%;
					}
					&.lv5{
						background-size: 100% 100%;
						border-radius: 5px;
    					// border: 1px solid rgb(96, 96, 113);
						background: linear-gradient(to top, rgba(81, 72, 72, 0), rgb(87, 92, 104));
            background: url('../../assets/images/985/home/5.png') no-repeat center;
            background-size: 100% 100%;
					}
					&.lv6{
						background-size: 100% 100%;
						border-radius: 5px;
    					// border: 1px solid rgba(233, 212, 90, 0.358);
						background: linear-gradient(to top, rgba(255,0,0,0), rgb(139, 111, 18));
            background: url('../../assets/images/q2/permutation/1.png') no-repeat center;
            background-size: 100% 100%;
					}
          span {
            display: flex;
            justify-content: center;
            margin-top: 2px;
            img {
              width: 0.4rem;
              height: 0.4rem;
            }
            font-size: 12px;
            color: #ccc;
          }
          .b_pic {
            width: 80px;
            height: 50px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          p {
            font-size: 12px;
            padding: 0 10px;
            box-sizing: border-box;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #fff;
          }
        }
      }
    }
  }
.Replacement_box {
  // padding: 20px;
  box-sizing: border-box;
  padding-top: 150px;
  padding-bottom: 100px;
  background: url('../../assets/images/985/replace/topbac.png') no-repeat center;
  background-size: 100% 100%;


  @keyframes zhuan {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .Q_center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 80px;
    // margin-bottom: 40px;
    background: url('../../assets/images/985/replace/nav.png') no-repeat center ;
    background-size: 100% 100%;
    .bigyuan{
      background: url('../../assets/images/q2/permutation/centerbac.png') no-repeat center;
      background-size: 100% 100%;
      width: 170px;
      height: 160px;
      // margin-top: 75px;
    }
    .yuan {
      width: 100px;
      height: 110px;
      background: url('../../assets/images/q2/permutation/center.png') no-repeat center;
      background-size: contain;
      text-align: center;
      line-height: 110px;
      font-size: 18px;
      position: relative;
      color: #f79a9a;
      margin: 27px auto;

      .Zhi {
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -5px;
        padding-top: 8px;
        box-sizing: border-box;
        .dian {
          width: 10px;
          height: 10px;
          background-color: #7cdffe;
          box-shadow: 0 0 6px 3px #7cdffe;
          border-radius: 50%;
        }
      }
      span {
        font-size: 18px;
      }
    }
    img {
      display: block;
      width: 50px;
      // transform: rotate(90deg);
      margin: 0 20px;
    }
    
  }

  .Show_box {
    width: 500px;
    padding: 10px;
    box-sizing: border-box;
    margin: 10px auto 0;
    position: relative;
    text-align: center;
    // background: rgba($color: #fff, $alpha: 0.8);
    color: #000;
    .Show_title {
      text-align: center;
      font-size: 18px;
    }
    .Img {
      width: 80%;
    }

    .Show_list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .Show_item {
        width: 150px;
        margin: 0 5px;
        margin-top: 10px;
        background: url('../../assets/images/q2/openbox/1.png') no-repeat
          center;
        background-size: 100% 100%;
        // border: 1px solid #e9b10e;
        box-sizing: border-box;
        cursor: pointer;
        padding: 10px;
        .Show_pic {
          width: 100px;
          height: 90px;
          margin: 0 auto;
          background-position: center;
          background-size: 80%;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 90%;
            max-height: 90%;
            vertical-align: middle;
          }
        }

        p {
          width: 100%;
          padding: 0 5px;
          box-sizing: border-box;
          font-size: 14px;
          color: #fff;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          color: #f1bc60;
          margin: 10px 0 10px;

          img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
        }
      }
    }

    .Show_btns {
      margin-top: 10px;

      .van-button {
        width: 160px;
        height: 40px;
        padding: 0;
        margin-right: 20px;
        font-size: 16px;
        // background-color: #ffd322;
        background: url('../../assets/images/q2/permutation/jixu.png') no-repeat center !important;
        background-size: 100% 100% !important;
        color: #000 !important;
        border: none;
        &:last-child {
          margin-right: 0;
        }

        .Gold {
          margin-left: 5px;
          color: #000;

          img {
            width: 18px;
            height: 18px;
            vertical-align: middle;
            margin-bottom: 2px;
            margin-right: 2px;
          }
        }
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .wrapper-box {
    .wrapper-box-top {
      width: 100%;
      background: linear-gradient(
        90deg,
        transparent,
        rgba(17, 20, 49, 0.8),
        transparent
      );
    }

    width: 300px;
    position: relative;
    border: 1px solid #0e0f14;
    background-color: #20222b;
    overflow: hidden;
    padding: 0 0.35rem;
    box-sizing: border-box;
    color: #fff;

    span {
      display: block;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
    }

    p {
      font-size: 14px;
      margin-bottom: 5px;
      line-height: 1.75em;
    }

    .Tutorial {
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      .title {
        width: 100%;
        height: 0.95rem;
        line-height: 0.95rem;
        text-align: center;
        font-size: 0.26rem;
      }

      .Tutorial-box {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .JianTou {
          margin-top: 0.2rem;
        }

        .Tutorial-item {
          width: 1.6rem;
          text-align: center;

          .pic {
            width: 0.8rem;
            height: 0.8rem;
            margin: 0 auto;

            img {
              width: 100%;
              height: 100%;
            }
          }

          p {
            font-size: 0.16rem;
            color: #fff;
            margin-top: 0.1rem;
          }

          .info {
            color: rgba(255, 255, 255, 0.6);
            font-size: 0.14rem;
            line-height: 1.75em;
          }
        }
      }
    }

    .cancel {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
      line-height: 20px;

      img {
        width: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>
